import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Container, Modal, Button } from "react-bootstrap";

import * as OrderAPI from "../../service/OrderAPI";
import * as ActionTypes from "../../store/ActionTypes";
import Layout from "../Layout";
import "../../css/checkout.css";
import AppLoader from "../common/AppLoader";


const Checkout = (props) => {

  const cardNumber1 = useRef(null);
  const cardNumber2 = useRef(null);
  const cardNumber3 = useRef(null);
  const cardNumber4 = useRef(null);

  const cardExpiryMonth = useRef(null);
  const cardExpiryYear = useRef(null);
  const cvv = useRef(null);
  const [name, setName] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  const cart = useSelector((state) => state.CartReducer.cart);
  const cartAmount = useSelector((state) => state.CartReducer.amount);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState([]);
  const [paying, setPaying] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {

    const customer = sessionStorage.getItem("customer");
    if (customer) {
      const parsed = JSON.parse(customer);
      if (parsed.user && parsed.user.address) {
        setShippingAddress(parsed.user.address);
      }

    }

  }, []);

  const cardExpiryMonthHandler = (evt) => {
    const data = evt.target.value;
    if (data.length >= 2) {
      cardExpiryYear.current.focus();
    }
  }

  const cardExpiryYearHandler = (evt) => {
    const data = evt.target.value;
    if (data.length < 1) {
      cardExpiryMonth.current.focus();
    }
  }

  const cardNumber1Handler = (evt) => {
    const data = evt.target.value;

    if (data.length >= 4) {
      cardNumber2.current.focus();
    }
  }

  const cardNumber2Handler = (evt) => {
    const data = evt.target.value;
    if (data.length < 1) {
      cardNumber1.current.focus();
    }
    else if (data.length >= 4) {
      cardNumber3.current.focus();
    }
  }

  const cardNumber3Handler = (evt) => {
    const data = evt.target.value;
    if (data.length < 1) {
      cardNumber2.current.focus();
    }
    else if (data.length >= 4) {
      cardNumber4.current.focus();
    }
  }

  const cardNumber4Handler = (evt) => {
    const data = evt.target.value;
    if (data.length < 1) {
      cardNumber3.current.focus();
    }
    else if (data.length >= 4) {
      cvv.current.focus();
    }
  }


  const cardCVVHandler = (evt) => {
    const data = evt.target.value;
    if (data.length >= 3) {
      cardExpiryMonth.current.focus();
    }
  }

  const cartItems = cart.map((item, index) => {
    return (
      <Row key={index} className="cart_row">
        <Col md={6} xs={12}>
          <div className="cart_image_zone">
            <div className="cart_image_zone_item">
              {item.image ? (
                <img
                  width="150px"
                  height="150px"
                  src={item.image}
                  alt="cart item"
                />
              ) : (
                <AppLoader />
              )}
            </div>
            <div className="cart_image_zone_item">
              <p className="cart_data">
                name:<span className="cart_data_bold">{item.name}</span>
              </p>
              <p className="cart_data">
                color:<span className="cart_data_bold">{item.color}</span>
              </p>
              <p className="cart_data">
                size:<span className="cart_data_bold">{item.size}</span>
              </p>
              <p className="cart_data">
                amount:<span className="cart_data_bold">₦{new Intl.NumberFormat().format(item.amount)}</span>
              </p>
            </div>
          </div>
        </Col>
        <Col md={3} xs={12}>
          <div className="cart_update">
            <p key={"input".concat(index)} className="cart_quantity">
              {item.quantity}
            </p>
          </div>
        </Col>
        <Col md={3} xs={12}>
          <p className="cart_data">
            ₦{new Intl.NumberFormat().format(parseInt(item.quantity) * parseInt(item.amount))}
          </p>
        </Col>
      </Row>
    );
  });

  const completeCheckOutHandler = (evt) => {
    evt.preventDefault();
    if (cart === undefined || cart.length === 0) {
      setErrors(["no item in cart"]);
      setShowErrors(true);
      return;
    }
    setPaying(true);
    const errs = [];

    setErrors(errs);

    if (errs.length === 0) {
      const order = {
        orderDetails: cart,
        shippingAddress: shippingAddress,
      }
      OrderAPI.addOrder(order).then(res => {
        dispatch({ type: ActionTypes.DELETE_CART });
        setPaying(false);
        navigate("/orderConfirmed", { replace: true });
      }).catch(err => {
        console.log(err);
        setPaying(false);
        if (err.response) {
          errs.push(JSON.stringify(err.response.data));
        }
        else {
          errs.push("an error occured. Could not process your request");
        }

        setShowErrors(true);
      })
    }
    else {
      setShowErrors(true);
      setPaying(false);
    }
  }

  const handleErrorDisplayClose = () => {
    setShowErrors(false);
    setErrors([]);
  }
  return (
    <Layout>
      <Modal show={showErrors} onHide={handleErrorDisplayClose}>
        <Modal.Header closeButton>
          <Modal.Title className="errorHeader">Order error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            errors?.map(value => {
              return <p className="errorItem">{value}</p>
            })
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleErrorDisplayClose}>
            Close
          </Button>

        </Modal.Footer>

      </Modal>
      <form className="main_checkout" method="POST" onSubmit={completeCheckOutHandler}>
        <section className="payment_checkout">
          <h2>Checkout</h2>
          <hr />

          <div className="payment_checkout_controls">
            <label className="checkout_label">Billing address</label>
            <textarea className="checkout_input_textarea" value={billingAddress}
              onChange={(evt) => setBillingAddress(evt.target.value)}></textarea>
          </div>

          <div className="payment_checkout_controls">
            <label className="checkout_label">Shipping address</label>
            <textarea className="checkout_input_textarea" value={shippingAddress} name="shippingAddress"
              onChange={(evt) => setShippingAddress(evt.target.value)}></textarea>
          </div>

          <hr />
          <h4>Payment Method</h4>
          <hr />
        </section>
        <section className="summary_checkout">
          <h3>Summary</h3>
          <div>
            <label className="final_description">Total price: </label>
            <label className="final_value">₦{new Intl.NumberFormat().format(parseInt(cartAmount))}</label>
          </div>
          <div>
            <label className="final_description">Discounts: </label>
            <label style={{ color: "orange" }} className="final_value">0%</label>
          </div>
          <hr />
          <div>
            <input disabled={paying} className="checkout_button" onClick={completeCheckOutHandler}
              type="submit" value={paying ? "processing..." : "Complete checkout"} />
          </div>
          <hr />
          <div>
            <Container>
              {cartItems}
            </Container>
          </div>
        </section>
      </form>
    </Layout>
  );
};

export default Checkout;
