import { Route, Routes } from "react-router";
import React, { Fragment, Suspense } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import "./App.css";
import HomePage from "./components/pages/HomePage";
import LoginPage from "./components/pages/LoginPage";
import AdminRoute from "./util/AdminRoute";
import ProductPage from "./components/pages/ProductPage";
import CustomerLoginPage from "./components/pages/CustomerLoginPage";
import CustomerRoute from "./util/CustomerRoute";
import Checkout from "./components/pages/Checkout";
import SignupPage from "./components/pages/SignupPage";
import AppLoader from "./components/common/AppLoader";
import AdminForgotPassword from "./components/pages/admin/AdminForgotPassword";
import CustomerForgotPassword from "./components/pages/CustomerForgotPassword";
import ViewImages from "./components/pages/admin/product/ViewImages";

function App() {
  const AdminHomePage = React.lazy(() =>
    import("./components/pages/admin/AdminHome")
  );
  const AddUserPage = React.lazy(() =>
    import("./components/pages/admin/user/AddUser")
  );
  const UsersPage = React.lazy(() =>
    import("./components/pages/admin/user/Users")
  );
  const ChangePasswordPage = React.lazy(() =>
    import("./components/pages/admin/user/ChangePassword")
  );
  const AllProductsPage = React.lazy(() =>
    import("./components/pages/admin/product/AllProducts")
  );
  const AddProductImagesPage = React.lazy(() =>
    import("./components/pages/admin/product/AddProductImages")
  );
  const AddProductSizePage = React.lazy(() =>
    import("./components/pages/admin/product/AddProductSize")
  );
  const AddProductPage = React.lazy(() =>
    import("./components/pages/admin/product/AddProduct")
  );
  const ValidateProductPage = React.lazy(() =>
    import("./components/pages/admin/product/ValidateProduct")
  );
  const EditProductPage = React.lazy(() =>
    import("./components/pages/admin/product/EditProduct")
  );
  const EditProductSizePage = React.lazy(() =>
    import("./components/pages/admin/product/EditSize")
  );
  const EditProductImagePage = React.lazy(() =>
    import("./components/pages/admin/product/EditImage")
  );
  const OrdersPage = React.lazy(() =>
    import("./components/pages/admin/order/Orders")
  );
  const AllColorsPage = React.lazy(() =>
    import("./components/pages/admin/color/AllColors")
  );
  const AddColorPage = React.lazy(() =>
    import("./components/pages/admin/color/AddColor")
  );
  const AllStylesPage = React.lazy(() =>
    import("./components/pages/admin/style/AllStyles")
  );
  const AddStylePage = React.lazy(() =>
    import("./components/pages/admin/style/AddStyle")
  );
  const AllMaterialsPage = React.lazy(() =>
    import("./components/pages/admin/material/AllMaterials")
  );
  const AddMaterialPage = React.lazy(() =>
    import("./components/pages/admin/material/AddMaterial")
  );
  const AllCategoryPage = React.lazy(() =>
    import("./components/pages/admin/category/AllCategory")
  );
  const AddCategoryPage = React.lazy(() =>
    import("./components/pages/admin/category/AddCategory")
  );
  const AddSizePage = React.lazy(() =>
    import("./components/pages/admin/size/AddSize")
  );
  const AllSizesPage = React.lazy(() =>
    import("./components/pages/admin/size/AllSizes")
  );
  const CartPage = React.lazy(() => import("./components/pages/CartPage"));
  const OrderConfirmedPage = React.lazy(() => import("./components/pages/OrderConfirmedPage"));
  const ProductDetailsPage = React.lazy(() =>
    import("./components/pages/ProductDetailsPage")
  );

  const AddImagePage = React.lazy(() => import("./components/pages/admin/product/AddImage"))
  return (
    <Fragment>
      <Suspense fallback={<AppLoader />}>
        <Routes>
          <Route path="/" element={<HomePage />}></Route>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/admin/forgotpassword" element={<AdminForgotPassword />} />
          <Route path="/customer/forgotpassword" element={<CustomerForgotPassword />} />
          <Route path="/customer/login" element={<CustomerLoginPage />} />
          <Route path="/customer/signup" element={<SignupPage />} />
          <Route path="/product/details/:id" element={<ProductDetailsPage />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/orderConfirmed"
            element={<CustomerRoute>
              <OrderConfirmedPage />
            </CustomerRoute>} />
          <Route
            path="/product/:gender/:productType/:style"
            element={<ProductPage />}
          />
          <Route
            path="/admin"
            element={
              <AdminRoute>
                <AdminHomePage />
              </AdminRoute>
            }
          ></Route>
          <Route
            path="/admin/create"
            element={
              <AdminRoute>
                <AddUserPage />
              </AdminRoute>
            }
          ></Route>
          <Route
            path="/admin/users"
            element={
              <AdminRoute>
                <UsersPage />
              </AdminRoute>
            }
          ></Route>
          <Route
            path="/admin/changePassword"
            element={
              <AdminRoute>
                <ChangePasswordPage />
              </AdminRoute>
            }
          ></Route>
          <Route
            path="/admin/products"
            element={
              <AdminRoute>
                <AllProductsPage />
              </AdminRoute>
            }
          ></Route>
          <Route
            path="/admin/product/add"
            element={
              <AdminRoute>
                <AddProductPage />
              </AdminRoute>
            }
          ></Route>
          <Route
            path="/admin/product/addImage"
            element={
              <AdminRoute>
                <AddProductImagesPage />
              </AdminRoute>
            }
          ></Route>
          <Route
            path="/admin/product/addSize"
            element={
              <AdminRoute>
                <AddProductSizePage />
              </AdminRoute>
            }
          ></Route>
          <Route
            path="/admin/product/validate"
            element={
              <AdminRoute>
                <ValidateProductPage />
              </AdminRoute>
            }
          ></Route>
          <Route
            path="/admin/edit/product/:id"
            element={
              <AdminRoute>
                <EditProductPage />
              </AdminRoute>
            }
          ></Route>
          <Route
            path="/admin/edit/product/sizes/:id"
            element={
              <AdminRoute>
                <EditProductSizePage />
              </AdminRoute>
            }
          ></Route>
          <Route
            path="/admin/edit/product/images/:id"
            element={
              <AdminRoute>
                <EditProductImagePage />
              </AdminRoute>
            }
          ></Route>
          <Route
            path="/admin/orders"
            element={
              <AdminRoute>
                <OrdersPage />
              </AdminRoute>
            }
          ></Route>
          <Route
            path="/admin/colors"
            element={
              <AdminRoute>
                <AllColorsPage />
              </AdminRoute>
            }
          ></Route>
          <Route
            path="/admin/colors/add"
            element={
              <AdminRoute>
                <AddColorPage />
              </AdminRoute>
            }
          ></Route>
          <Route
            path="/admin/styles"
            element={
              <AdminRoute>
                <AllStylesPage />
              </AdminRoute>
            }
          ></Route>
          <Route
            path="/admin/styles/add"
            element={
              <AdminRoute>
                <AddStylePage />
              </AdminRoute>
            }
          ></Route>
          <Route
            path="/admin/materials"
            element={
              <AdminRoute>
                <AllMaterialsPage />
              </AdminRoute>
            }
          ></Route>
          <Route
            path="/admin/materials/add"
            element={
              <AdminRoute>
                <AddMaterialPage />
              </AdminRoute>
            }
          ></Route>
          <Route
            path="/admin/category"
            element={
              <AdminRoute>
                <AllCategoryPage />
              </AdminRoute>
            }
          ></Route>
          <Route
            path="/admin/category/add"
            element={
              <AdminRoute>
                <AddCategoryPage />
              </AdminRoute>
            }
          ></Route>
          <Route
            path="/admin/sizes"
            element={
              <AdminRoute>
                <AllSizesPage />
              </AdminRoute>
            }
          ></Route>
          <Route
            path="/admin/sizes/add"
            element={
              <AdminRoute>
                <AddSizePage />
              </AdminRoute>
            }
          ></Route>
          <Route
            path="/admin/product/images"
            element={
              <AdminRoute>
                <ViewImages />
              </AdminRoute>
            }
          ></Route>
          <Route
            path="/admin/product/images/add"
            element={
              <AdminRoute>
                <AddImagePage />
              </AdminRoute>
            }
          ></Route>
          <Route
            path="/checkout"
            element={
              <CustomerRoute>
                <Checkout />
              </CustomerRoute>
            }
          ></Route>
        </Routes>
      </Suspense>
    </Fragment>
  );
}

export default App;
