import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import secureLocalStorage from "react-secure-storage";

import { signupCustomer } from "../../service/CustomerAPI";
import logo from "../../images/logo.png";
import Layout from "../Layout";
import "../../css/login.css";
import AppLoader from "../common/AppLoader";
import { BsHouseFill, BsMailbox2, BsPerson, BsPersonFill, BsPhoneFill } from "react-icons/bs";

const SignupPage = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const navigate = useNavigate();

  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState("");

  const submitHandler = (evt) => {
    setProcessing(true);
    evt.preventDefault();
    const data = {
      firstName: firstName,
      lastName: lastName,
      phone: phone,
      email: email,
      address: address
    };

    signupCustomer(data).then((rsp) => {
      console.log(rsp.data);
      secureLocalStorage.setItem("customer", JSON.stringify(rsp.data));
      setProcessing(false);
      navigate("/checkout", { replace: true });
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }).catch((err) => {
      console.log(err);
      if (err.response) {
        setError(err.response.data.message);
      }
      else {
        setError(err.message);
      }
      setProcessing(false);
    });
  };

  const changeHandler = (evt) => {
    const name = evt.target.name;
    if (name === "firstName") {
      setFirstName(evt.target.value);
    } else if (name === "lastName") {
      setLastName(evt.target.value);
    } else if (name === "phone") {
      setPhone(evt.target.value);
    } else if (name === "email") {
      setEmail(evt.target.value);
    }
    else if (name === "address") {
      setAddress(evt.target.value);
    }
  };

  return (
    <Layout>
      <form className="login_block" onSubmit={submitHandler}>
        <h2>Welcome to <img src={logo} width={120} height={50} alt="logo" /></h2>
        <p className="login_statement">Enter details to create account</p>
        <div className="login_input_box">
          <label className="login_label">First Name</label>
          <div className="signup_login_div_input">
            <input
              className="login_input"
              type="text"
              name="firstName"
              value={firstName}
              onChange={changeHandler}
            />
            <BsPerson size={25} />
          </div>

        </div>

        <div className="login_input_box">
          <label className="login_label">Last Name</label>
          <div className="signup_login_div_input">
            <input
              className="login_input"
              type="text"
              name="lastName"
              value={lastName}
              onChange={changeHandler}
            />
            <BsPersonFill size={25} />
          </div>
        </div>

        <div className="login_input_box">
          <label className="login_label">Phone</label>
          <div className="signup_login_div_input">
            <input
              className="login_input"
              type="text"
              pattern="[0-9]{11}"
              name="phone"
              value={phone}
              maxLength={11}
              minLength={11}
              onChange={changeHandler}
            />
            <BsPhoneFill size={25} />
          </div>
        </div>

        <div className="login_input_box">
          <label className="login_label">E-mail</label>
          <div className="signup_login_div_input">
            <input
              className="login_input"
              type="email"
              name="email"
              value={email}
              onChange={changeHandler}
            />
            <BsMailbox2 size={25} />
          </div>
        </div>

        <div className="login_input_box">
          <label className="login_label">Address</label>
          <div className="signup_login_div_input">
            <textarea
              className="login_input"
              type="text"
              name="address"
              value={address}
              onChange={changeHandler}
            />
            <BsHouseFill size={25}/>
          </div>
        </div>

        <div className="login_input_box">
          <p className="common_error">{error}</p>
          {processing ? (
            <AppLoader />
          ) : (
            <input
              className="login_button2"
              type="submit"
              value="Sign up"
              onSubmit={submitHandler}
            />
          )}
          <Link className="login_anchor" to="/">
            Go to home page
          </Link>
        </div>
      </form>
    </Layout>
  );
};

export default SignupPage;
