import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { BsEye, BsEyeSlash, BsPerson } from "react-icons/bs";

import logo from "../../images/logo.png";
import Layout from "../Layout";
import "../../css/login.css";

const CustomerForgotPassword = ()=>{

    const [email, setEmail] = useState("");
    const [processing, setProcessing] = useState(false);
   
    const [error, setError] = useState("");
 
    const navigate = useNavigate();
  

    const submitHandler = (evt) => {
      evt.preventDefault();
  
      if (email.trim().length === 0 ) {
        setError("email is required");
        return;
      }
      setProcessing(true);
      setError("");
  
      axios
        .patch("/customerforgotpassword", {email: email}, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then((rsp) => {
          
          alert("New password sent to email provided");
          navigate("/customer/login", {replace: true});
          // eslint-disable-next-line no-restricted-globals
        })
        .catch((error) => {
          console.log(error);
          setError(error.response.data.error || error.message);
        }).finally(()=>{
            setProcessing(false);
        });
    };
  
    const changeHandler = (evt) => {
      setEmail(evt.target.value);
    };
  
    
    return (
      <Layout>
        <div className="customer_login_section">
          <div className="customer_login_section_login">
            <form onSubmit={submitHandler}>
              <h2>Welcome to <img src={logo} width={120} height={50} alt="logo" /></h2>
              <p className="login_statement">Enter details to request new password</p>
              <div className="login_input_box">
                <label className="login_label">E-mail</label>
                <div className="customer_login_div_input">
                  <input
                    className="login_input"
                    type="email"
                    name="email"
                    value={email}
                    onChange={changeHandler}
                  />
                  <BsPerson size={25} />
  
                </div>
  
              </div>
  
        
              <div className="login_input_box">
                <p className="common_error">{error}</p>
                {processing ? (
                  <Spinner animation="border" className="login_spinner"></Spinner>
                ) : (
                  <input
                    className="login_button2"
                    type="submit"
                    value="Send"
                    onSubmit={submitHandler}
                  />
                )}
                
                <Link className="login_anchor" to="/customer/login">
                  Go to login page
                </Link>
              </div>
            </form>
          </div>
          <div className="customer_login_section_signup">
            <h2>Create your <img src={logo} width={120} height={50} alt="logo" /> account</h2>
  
            <p className="login_statement">
              Creating an account helps you keep track of all your <br /> orders
              and also helps you check out <br /> quickly using previous
              destination address
            </p>
  
            <Link className="login_button2" to="/customer/signup">
              Click to create an account
            </Link>
          </div>
        </div>
      </Layout>
    );

}

export default CustomerForgotPassword;