import { Spinner } from "react-bootstrap";

const AppLoader = () => {
    return <div className="spinnerContainer">
        <Spinner animation="border" className="spinner">
        </Spinner>
    </div>

}

export default AppLoader;