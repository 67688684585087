import  secureLocalStorage from  "react-secure-storage";

const customerInfo = () => {
  
    if (secureLocalStorage.getItem("customer")) {
      console.log("customer ", secureLocalStorage.getItem("customer"));
      return JSON.parse(secureLocalStorage.getItem("customer"));
    }
    return { token: null,message: null, user: { sub: null, address: null } };
  };
  
  export default customerInfo;