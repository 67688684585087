import axios from "axios";
import customerInfo from "./CustomerInfo";
import userInfo from "./UserInfo";

const adminUser = userInfo();

const user = customerInfo();

export const addOrder = async (data) => {
  return await axios.post("/customer/order", {...data, email: user.user.sub}, {
    headers: {
      "Content-Type": "application/json",
      Authorization: user.token,
    },
  });
};

export const orders = async (pageNumber, pageSize) => {
  return await axios.get("/admin/order/"
  .concat(pageNumber).concat("/").concat(pageSize),  {
    headers: {
      "Content-Type": "application/json",
      Authorization: adminUser.token,
    },
  });
};

export const frequentlyBought = async () => {
  return await axios.get("/order/frequentlybought",  {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const updateOrders = async (data) => {
  return await axios.put("/admin/orders",data,  {
    headers: {
      "Content-Type": "application/json",
      Authorization: adminUser.token,
    },
  });
};

export const deleteOrders = async (orderId) => {
  return await axios.delete("/admin/orders/"
  .concat(orderId),  {
    headers: {
      "Content-Type": "application/json",
      Authorization: adminUser.token,
    },
  });
};