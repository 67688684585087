import { combineReducers, configureStore } from "@reduxjs/toolkit";
import CartReducer from "./CartReducer";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const Store = configureStore({
  reducer: persistReducer(persistConfig, combineReducers({ CartReducer })),
});

export default Store;
