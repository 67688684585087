import { Navigate, useLocation } from "react-router";
import secureLocalStorage from 'react-secure-storage';

const loggedIn = () => {
  if (secureLocalStorage.getItem("user")) {
    const userDetails = JSON.parse(secureLocalStorage.getItem("user"));

    if (
      userDetails &&
      (userDetails.user.role.includes("admin") ||
        userDetails.user.role.includes("superAdmin")) &&
      userDetails.token
    ) {
      return true;
    }
  }
  return false;
};

export const useLoginInfo = () => {
  if (secureLocalStorage.getItem("user")) {
    return JSON.parse(secureLocalStorage.getItem("user"));
  }
  return { token: null, user: { sub: null, role: [] } };
};

const AdminRoute = ({ children }) => {
  let authenticated = loggedIn();
  let location = useLocation();

  return authenticated ? (
    children
  ) : (
    <Navigate to={"/login"} replace={true} state={{ from: location }} />
  );
};

export default AdminRoute;
