import secureLocalStorage from 'react-secure-storage';
const userInfo = () => {
  if (secureLocalStorage.getItem("user")) {
    return JSON.parse(secureLocalStorage.getItem("user"));
  }
  return { token: null, user: { sub: null, role: [] } };
};


export default userInfo;
