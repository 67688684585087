import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { BsEye, BsEyeSlash, BsPerson } from "react-icons/bs";

import logo from "../../images/logo.png";
import "../../css/login.css";


const LoginPage = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [error, setError] = useState("");
  const location = useLocation();
  let from = location.state?.from?.pathname || "/admin";
  const navigate = useNavigate();

  useEffect(() => {
    if (loggedIn === true) {
      navigate(from, { replace: true });
    }
  }, [loggedIn, from, navigate]);

  const submitHandler = (evt) => {
    evt.preventDefault();

    if (username.trim().length === 0 || password.trim().length === 0) {
      setError("username and password is required");
      return;
    }
    setProcessing(true);
    setError("");

    axios
      .post("/login", null, {
        headers: {
          username: username,
          password: password,
        },
      })
      .then((rsp) => {
        secureLocalStorage.setItem("user", JSON.stringify(rsp.data));
        setLoggedIn(true);
        setProcessing(false);
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        setProcessing(false);
        setError(error.response.data.error);
      });
  };

  const changeHandler = (evt) => {
    const name = evt.target.name;
    if (name === "username") {
      setUsername(evt.target.value);
    } else if (name === "password") {
      setPassword(evt.target.value);
    }
  };

  const handlePasswordVisibility = (evt) => {
    setPasswordVisible((prev) => !prev);
  }

  return (
    <form className="login_block" onSubmit={submitHandler}>
      <h2>Welcome to <img src={logo} width={120} height={50} alt="logo" /></h2>
      <p className="login_statement">Enter details to login</p>
      <div className="login_input_box">
        <label className="login_label">Username</label>
        <div className="login_div_input">
          <input
            className="login_input"
            type="text"
            name="username"
            value={username}
            onChange={changeHandler}
          />
          <BsPerson size={30} />
        </div>

      </div>

      <div className="login_input_box">
        <label className="login_label">Password</label>
        <div className="login_div_input">
          <input
            className="login_input"
            type={passwordVisible?"text":"password"}
            name="password"
            value={password}
            onChange={changeHandler}
          />
          {passwordVisible ? <BsEye onClick={handlePasswordVisibility} size={25} /> : <BsEyeSlash onClick={handlePasswordVisibility} size={25} />}
        </div>
      </div>

      <div className="login_input_box">
        <p className="common_error">{error}</p>
        {processing ? (
          <Spinner animation="border" className="login_spinner"></Spinner>
        ) : (
          <input
            className="login_button"
            type="submit"
            value="Login"
            onSubmit={submitHandler}
          />
        )}
        <Link className="login_anchor" to="/admin/forgotpassword">
          Forgot password
        </Link>
        <Link className="login_anchor" to="/">
          Go to home page
        </Link>
      </div>
    </form>
  );
};

export default LoginPage;
