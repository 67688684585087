import { Link } from "react-router-dom";
import React from "react";
import { Img } from "react-image";

import "../../css/product.css";
import loadingImage from "../../images/loading.gif";

const Product = (props) => {
  return (
    <div className="product_main">
      <Img width={props.width} height={props.height} src={props.image}
        loader={<img src={loadingImage} alt="loading gif" width={props.width} height={props.height} />} />

      <span className="product_name">{props.name}</span>

      <span className="product_sale_price">₦{new Intl.NumberFormat().format(props.salePrice)}</span>
      <span className="product_unit_price">₦{new Intl.NumberFormat().format(props.unitPrice)}</span>

      <Link className="product_details_link" to={props.link}>
        view
      </Link>
    </div>
  );
};

export default Product;
