import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { BsFillBasketFill } from "react-icons/bs";
import { Spinner } from "react-bootstrap";
import secureLocalStorage from "react-secure-storage";

import * as ProductAPI from "../service/ProductAPI";
import "../css/header.css";
import girl from "../images/girl.jpg";
import boy from "../images/boy.jpg";
import logo from "../images/logo.png";

const Header = (props) => {
  const navigate = useNavigate();
  const cartCount = useSelector((state) => state.CartReducer.quantity);
  const [link, setLink] = useState();
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("headerLinks")) {
      setLink(JSON.parse(sessionStorage.getItem("headerLinks")));
    } else {
      ProductAPI.getHeaderLinks()
        .then((data) => {
          setLink(data.data);
          sessionStorage.setItem("headerLinks", JSON.stringify(data.data));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    if (secureLocalStorage.getItem("customer")) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, []);

  const headerLinks = link?.map((header) => {
    return (
      <li
        key={header.header}
        className="nav-item dropdown dropdown-mega position-static"
      >
        <a
          className="nav-link dropdown-toggle link2"
          style={{ color: "#fff" }}
          href="#"
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
        >
          {header.header}
        </a>
        <div className={`dropdown-menu shadow `}>
          <div className="mega-content px-4">
            <div className="container-fluid">
              <div className="row">
                {header.data.map((data) => {
                  return (
                    <div
                      key={data.type}
                      className="col-12 col-sm-4 col-md-3 py-4"
                    >
                      <h5 className="header_anchor__data">{data.type}</h5>
                      <div className="list-group">
                        {data.entry.map((entry) => {
                          return (
                            <Link
                              className="header_anchor__links"
                              key={entry.concat(data.type)}
                              to={`/product/${header.header}/${data.type}/${entry}`}
                            >
                              {entry}
                            </Link>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
                <div className="col-12 col-sm-4 col-md-3 py-4">
                  <div className="card">
                    <img src={header.header === 'Girls'? girl : boy} className="img-fluid" width={1080} height={1080} alt="logo" />
                    <div className="card-body">
                      <p className="card_image_data">Cool designs</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    );
  });

  return (
    <nav className="navbar navbar-expand-lg appbar">
      <div className="container-fluid">
        <Link to="/" className="navbar-brand navBrand appnavbrand">
         <img src={logo} width={80} height={40} alt="logo"/>
        </Link>

        <button
          style={{ border: "1px solid #fff" }}
          className="navbar-toggler collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbar-content"
        >
          <div className="hamburger-toggle">
            <div className="hamburger">
              <span style={{ color: "#fff" }}>-</span>
              <span style={{ color: "#fff" }}>-</span>
              <span style={{ color: "#fff" }}>-</span>
            </div>
          </div>
        </button>
        <div className="collapse navbar-collapse" id="navbar-content">
          <ul className="navbar-nav mr-auto mb-2 mb-lg-0">{link === undefined ? 
          <li><Spinner style={{color:"white"}} className="spinner" animation="border" /></li>: headerLinks}</ul>
          <div className="d-flex ms-auto">
            <div className="cart_zone">
              <div className="cart_zone_cart_elements">
                <div>
                  <p className="cart_zone__count">{cartCount}</p>
                </div>

                <div
                  className="cart_zone__image_container"
                  onClick={() => navigate("/cart")}
                >
                  <span className="tooltiptext">cart</span>
                  <BsFillBasketFill className="cart_zone__image" />
                </div>
              </div>
              <div>
                {loggedIn ? <div className="header_anchor" onClick={(evt)=>{
                  secureLocalStorage.removeItem("customer");
                  setLoggedIn(false);
                  navigate("/", {replace: true});
                }}>logout</div> : 
                <Link className="header_anchor" to="/customer/login">
                  login
                </Link>}
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
