import { Fragment } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Advert from "./Advert";

const Layout = (props) => {
  return (
    <Fragment>
      <Header />
      <main className="main">
        <Advert/>
        {props.children}
      </main>
      <Footer />
    </Fragment>
  );
};

export default Layout;
