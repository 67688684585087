import {  useState } from "react";
import { useNavigate } from "react-router";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";


import logo from "../../../images/logo.png";
import "../../../css/login.css";

const AdminForgotPassword = () => {

  
    const [email, setEmail] = useState("");

    const [processing, setProcessing] = useState(false);

    const [error, setError] = useState("");


    const navigate = useNavigate();



    const submitHandler = (evt) => {
        evt.preventDefault();

        if (email.trim().length === 0 ) {
            setError("email is required");
            return;
        }
        setProcessing(true);
        setError("");

        axios
            .patch("/forgotpassword", {email: email}, {
                headers: {
                    "content-type": "application/json",
                },
            })
            .then((rsp) => {
                secureLocalStorage.removeItem("user");
                alert("new password sent to email created");
                navigate("/login", {replace: true});

            })
            .catch((error) => {
                console.log(JSON.stringify(error));

                setError(error.response.data.error || error.message);
            }).finally(() => {
                setProcessing(false);
            });
    };

    const changeHandler = (evt) => {
        
      setEmail(evt.target.value);
        
    };



    return (
        <form className="login_block" onSubmit={submitHandler}>
            <h2>Welcome to <img src={logo} width={120} height={50} alt="logo" /></h2>
            <p className="login_statement">Enter details to to request new password</p>

            <div className="login_input_box">
                <label className="login_label">E-mail</label>
                <div className="login_div_input">
                    <input
                        className="login_input"
                        type="email"
                        value={email}
                        onChange={changeHandler}
                    />

                </div>
            </div>

            
            <div className="login_input_box">
                <p className="common_error">{error}</p>
                {processing ? (
                    <Spinner animation="border" className="login_spinner"></Spinner>
                ) : (
                    <input
                        className="login_button"
                        type="submit"
                        value="request"
                        onSubmit={submitHandler}
                    />
                )}
                <Link className="login_anchor" to="/login">
                    Go to login
                </Link>
            </div>
        </form>
    );

}

export default AdminForgotPassword;