import { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";


import AdminLayout from "../AdminLayout";
import "../../../../css/adminCommon.css";
import * as ProductAPI from "../../../../service/ProductAPI";
import DataPagination from "./DataPagination";

const ViewImages = () => {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [active, setActive] = useState(1);
  const [count, setCount] = useState(0);

  useEffect(() => {
    ProductAPI.getImages(pageNumber, pageSize)
      .then((rsp) => {
        setData(rsp.data);
        setCount(rsp.data.count);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, [pageNumber]);

  const deleteImage = (evt) => {
    const name = evt.target.name;
    ProductAPI.deleteImage(name)
      .then(() => {
        window.location.reload(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dataDisplay = data?.images?.map((image) => {
    return (
      <Row key={image._id}>
        <Col md={3} xs={12}>
          <p className="table_data">{image._id}</p>
        </Col>
        <Col md={4} xs={12}>
          <p className="table_data">{image.imageName}</p>
        </Col>
        <Col md={2} xs={12}>
          <p className="table_data"><img src={image.link} width={100} height={100} alt="image" /></p>
        </Col>
        <Col md={3} xs={12}>
          <button
            name={image.imageName}
            onClick={deleteImage}
            className="action_link"
          >
            delete
          </button>
        </Col>

      </Row>
    );
  });

  return (
    <AdminLayout>
      <Container className="table_background">
        <Row>
          <Col md={3} xs={12}>
            <b className="table_heading">Id</b>
          </Col>
          <Col md={4} xs={12}>
            <b className="table_heading">name</b>
          </Col>
          <Col md={2} xs={12}>
            <b className="table_heading">image</b>
          </Col>
          <Col md={3} xs={12}>
            <b className="table_heading">edit</b>
          </Col>

        </Row>
        {isLoading ? <Spinner animation="border" /> : dataDisplay}
        {error ? <p>error fetching data</p> : null}
        <Row>
          <Col md={12} sm={12}>
            <DataPagination count={count} handlePageClick={(page) => {
              setPageNumber(page);
              setActive(page);
            }} active={active} pageSize={pageSize} />
          </Col>
        </Row>
      </Container>
    </AdminLayout>
  );
};

export default ViewImages;