import * as ActionTypes from "../ActionTypes";

const computeState = (cart = [])=>{
  let amount = 0;
  let quantity = 0;

  cart.forEach((item)=>{
    quantity+=item.quantity;
    if(item.quantity >= item.wholesaleQuantity)
    {
      amount+=(item.quantity*item.wholesalePrice);
    }
    else{
      amount+=(item.quantity*item.amount);
    }
  })

  return {amount: amount, quantity: quantity};
}

const CartReducer = (state = { cart: [], quantity: 0, amount: 0 }, action) => {
  
  switch (action.type) {
    case ActionTypes.VIEW_CART:
      return state;
    case ActionTypes.ADD_TO_CART:
      if (state.cart.length === 0) {
        const newCart = [action.payLoad];
        const data = computeState(newCart);
        
        state = {
          cart: newCart,
          quantity: data.quantity,
          amount: data.amount
        };
      } else {
        let item = state.cart.find(
          (e) =>
            e.id === action.payLoad.id &&
            e.color === action.payLoad.color &&
            e.size === action.payLoad.size
        );

        if (item) {
          item = { ...item, quantity: item.quantity + action.payLoad.quantity };
          
          let filtered = state.cart.filter(
            (e) =>
              e.id !== action.payLoad.id ||
              e.color !== action.payLoad.color ||
              e.size !== action.payLoad.size
          );

          if (item.quantity <= 0) {
            const newCart = [...filtered];
            const data = computeState(newCart);
            state = {
              cart: newCart,
              quantity: data.quantity,
              amount: data.amount,
            };
          } else {
            const newCart = [...filtered, item];
            const data = computeState(newCart);
            state = {
              cart: newCart,
              quantity: data.quantity,
              amount: data.amount,
            };
          }
        } else {
          const newCart = [...state.cart, action.payLoad];
          const data = computeState(newCart);
          state = {
            cart: newCart,
            quantity: data.quantity,
            amount: data.amount,
          };
        }
      }
      return state;
    case ActionTypes.REMOVE_FROM_CART:
      if (state.cart.length > 0) {
        let filtered = state.cart.filter(
          (e, index) => index !== parseInt(action.payLoad.index)
        );
        const newCart = [...filtered];
        const data = computeState(newCart);
      
        state = {
          cart: newCart,
          quantity: data.quantity,
          amount: data.amount,
        };
      }
      return state;
    case ActionTypes.DELETE_CART:
      state = {
        cart: [],
        quantity: 0,
        amount: 0,
      };
      return state;
    default:
      return state;
  }
};

export default CartReducer;
