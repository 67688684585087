import axios from "axios";
import userInfo from "./UserInfo";

export const getAllProduct = async (pageNumber, pageSize) => {
  return await axios.get(`/products/${pageNumber}/${pageSize}`);
};

export const getLatestProducts = async () => {
  return await axios.get("/products/latest");
};

export const getSalesProducts = async () => {
  return await axios.get("/products/sales");
};

export const addProduct = async (data) => {
  const user = userInfo();
  return await axios.post("/admin/product/add", data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: user.token,
    },
  });
};

export const getProduct = async (id) => {
  return await axios.get(`/product/${id}`);
};

export const updateProduct = async (data, id) => {
  const user = userInfo();
  return await axios.put("/admin/product/".concat(id), data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: user.token,
    },
  });
};

export const deleteImage = async (name) => {
  const user = userInfo();
  return await axios.delete("/admin/product/image/".concat(name), {
    headers: {
      Authorization: user.token,
    },
  });
};

export const getImages = async (page_number, page_size) => {
  const user = userInfo();
  return await axios.get(`/admin/product/image/${page_number}/${page_size}`, {
    headers: {
      Authorization: user.token,
    },
  });
};

export const getGenders = async () => {
  return await axios.get("/gender");
};

export const getTypes = async () => {
  return await axios.get("/types");
};

export const getStyles = async () => {
  return await axios.get("/styles");
};

export const getAdminStyles = async (page_number, page_size) => {
  const user = userInfo();
  return await axios.get("/admin/styles/"+page_number+"/"+page_size,{
    headers: {
      Authorization: user.token,
    },
  });
};

export const addAdminStyles = async (data) => {
  const user = userInfo();
  return await axios.post("/admin/styles",data, {
    headers: {
      Authorization: user.token,
    },
  });
};

export const deleteAdminStyles = async (id) => {
  const user = userInfo();
  return await axios.delete("/admin/styles/".concat(id),{
    headers: {
      Authorization: user.token,
    },
  });
};

export const addAdminCategory = async (body) => {
  const user = userInfo();
  return await axios.post("/admin/category/",body,  {
    headers: {
      Authorization: user.token,
    },
  });
};

export const getAdminCategory = async (page_number, page_size) => {
  const user = userInfo();
  return await axios.get("/admin/category/"+page_number+"/"+page_size, {
    headers: {
      Authorization: user.token,
    },
  });
};

export const deleteAdminCategory = async (id) => {
  const user = userInfo();
  return await axios.delete("/admin/category/".concat(id), {
    headers: {
      Authorization: user.token,
    },
  });
};

export const getCategories = async () => {
  return await axios.get("/category");
};

export const addAdminMaterial = async (body) => {
  const user = userInfo();
  return await axios.post("/admin/material/",body,  {
    headers: {
      Authorization: user.token,
    },
  });
};

export const deleteAdminMaterial = async (id) => {
  const user = userInfo();
  return await axios.delete("/admin/materials/".concat(id), {
    headers: {
      Authorization: user.token,
    },
  });
};

export const getAdminMaterials = async (page_number, page_size) => {
  const user = userInfo();
  return await axios.get("/admin/materials/"+page_number+"/"+page_size, {
    headers: {
      Authorization: user.token,
    },
  });
};

export const getMaterials = async () => {
  return await axios.get("/material");
};

export const getSizes = async () => {
  return await axios.get("/sizes");
};

export const getAdminColors = async (page_number, page_size) => {
  const user = userInfo();
  return await axios.get("/admin/colors/"+page_number+"/"+page_size, {
    headers: {
      Authorization: user.token,
    },
  });
};

export const deleteAdminColor = async (id) => {
  const user = userInfo();
  return await axios.delete("/admin/colors/".concat(id), {
    headers: {
      Authorization: user.token,
    },
  });
};

export const addAdminColor = async (body) => {
  const user = userInfo();
  return await axios.post("/admin/colors/",body,  {
    headers: {
      Authorization: user.token,
    },
  });
};

export const getColors = async () => {
  return await axios.get("/colors");
};

export const addNewSize = async (newRecord, id) => {
  const user = userInfo();
  return await axios.put(
    "/admin/product/size/".concat(id).concat("/add"),
    newRecord,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: user.token,
      },
    }
  );
};

export const updateSize = async (newRecord, id) => {
  const user = userInfo();
  return await axios.put(
    "/admin/product/size/".concat(id).concat("/update"),
    newRecord,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: user.token,
      },
    }
  );
};

export const addSizeEntry = async (data, id) => {
  const user = userInfo();
  return await axios.post(
    "/admin/sizes",
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: user.token,
      },
    }
  );
};

export const deleteSizeEntry = async (id) => {
  const user = userInfo();
  return await axios.delete(
    "/admin/sizes/".concat(id),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: user.token,
      },
    }
  );
};

export const getSizeEntries = async (page_number, page_size) => {
  const user = userInfo();
  return await axios.get(
    "/admin/sizes/"+page_number+"/"+page_size,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: user.token,
      },
    }
  );
};

export const deleteProduct = async (id) => {
  const user = userInfo();
  return await axios.delete("/admin/product/".concat(id), {
    headers: {
      Authorization: user.token,
    },
  });
};

export const addNewImage = async (newImage, id) => {
  const user = userInfo();
  return await axios.put(
    "/admin/product/image/".concat(id).concat("/add"),
    newImage,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: user.token,
      },
    }
  );
};

export const getFooterLinks = async () => {
  return await axios.get("/products/footer");
};

export const getHeaderLinks = async () => {
  return await axios.get("/products/header");
};

export const getProductsCatalog = async (
  gender,
  type,
  style,
  pageNumber,
  pageSize
) => {
  return await axios.get(
    "/products/catalog/"
      .concat(gender.toLowerCase())
      .concat("/")
      .concat(type.toLowerCase())
      .concat("/")
      .concat(style.toLowerCase()),
    {
      headers: {
        page_number: pageNumber,
        page_size: pageSize,
      },
    }
  );
};

export const getProductsCatalogWithSearch = async (
  gender,
  type,
  style,
  pageNumber,
  pageSize,
  searchTerms
) => {
  return await axios.post(
    "/products/catalog/"
      .concat(gender.toLowerCase())
      .concat("/")
      .concat(type.toLowerCase())
      .concat("/")
      .concat(style.toLowerCase()),
      searchTerms,
    {
      headers: {
        page_number: pageNumber,
        page_size: pageSize,
      },
    }
  );
};

export const getBrands = async (gender, type, style) => {
  return await axios.get(
    "/products/brands/"
      .concat(gender.toLowerCase())
      .concat("/")
      .concat(type.toLowerCase())
      .concat("/")
      .concat(style.toLowerCase())
  );
};

export const getFilterColors = async (gender, type, style) => {
  return await axios.get(
    "/products/colors/"
      .concat(gender.toLowerCase())
      .concat("/")
      .concat(type.toLowerCase())
      .concat("/")
      .concat(style.toLowerCase())
  );
};

export const getFilterSizes = async (gender, type, style) => {
  return await axios.get(
    "/products/sizes/"
      .concat(gender.toLowerCase())
      .concat("/")
      .concat(type.toLowerCase())
      .concat("/")
      .concat(style.toLowerCase())
  );
};
