import axios from 'axios';

export const signupCustomer = async (customer) => {

    return await axios.post("/customer/signup", customer, {
        headers: {
            "Content-Type": "application/json"
        }
    });

}

