import { useState } from "react";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";

import "../../css/productItems.css";


const Accordion = (props) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <section>
      <header
        className="product_accordion_header"
        onClick={() => setIsActive((prev) => !prev)}
      >
        {isActive ? (
          <AiFillCaretDown className="caret caret-down" />
        ) : (
          <AiFillCaretUp className="caret" />
        )}
        {props.header}
      </header>
      {isActive && (
        <div className="product_accordion_body">{props.children}</div>
      )}
    </section>
  );
};

export default Accordion;
