import { Carousel } from "react-bootstrap";

const ImageSlider = (props) => {

    const items = props.items.map((i) => <Carousel.Item key={i.alt}>
        <img
            className={props.className}
            src={i.src}
            alt={i.alt}
        />
        <Carousel.Caption>
            <h3>{i.header}</h3>
            <p>{i.description}</p>
        </Carousel.Caption>
    </Carousel.Item>);
    return (
        <Carousel fade interval={3000}>
            {items}
        </Carousel>
    )
}

export default ImageSlider;