import { Navigate, useLocation } from "react-router";
import secureLocalStorage from "react-secure-storage";

const loggedIn = () => {
  if (secureLocalStorage.getItem("customer")) {
    const userDetails = JSON.parse(secureLocalStorage.getItem("customer"));

    if (userDetails && userDetails.token) {
      return true;
    }
  }
  return false;
};

export const useLoginInfo = () => {
  if (secureLocalStorage.getItem("customer")) {
    return JSON.parse(secureLocalStorage.getItem("customer"));
  }
  return { token: null, user: { sub: null, address: null } };
};

const CustomerRoute = ({ children }) => {
  let authenticated = loggedIn();
  let location = useLocation();

  return authenticated ? (
    children
  ) : (
    <Navigate
      to={"/customer/login"}
      replace={true}
      state={{ from: location }}
    />
  );
};

export default CustomerRoute;
