import { Carousel } from "@trendyol-js/react-carousel";
import React, { useEffect, useState } from "react";

import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

import Desktop6 from "../../images/Desktop6.jpg";
import Desktop7 from "../../images/Desktop7.jpg";
import Desktop8 from "../../images/Desktop8.jpg";
import Desktop3 from "../../images/Desktop3.png";

import ImageSlider from "../common/ImageSlider";
import Product from "../common/Product";
import Layout from "../Layout";
import * as ProductAPI from "../../service/ProductAPI";
import * as OrderAPI from "../../service/OrderAPI";
import AppLoader from "../common/AppLoader";

const HomePage = (props) => {
  const banners = [
    {
      src: Desktop8,
      alt: "banner 1",
      header: "",
      description: "",
    },
    {
      src: Desktop3,
      alt: "banner 2",
      header: "",
      description: "",
    },
    {
      src: Desktop6,
      alt: "banner 3",
      header: "",
      description: "",
    },
    {
      src: Desktop7,
      alt: "banner 4",
      header: "",
      description: "",
    }
  ];
  const rightArrow = <FaAngleRight className="common_arrow" />;
  const leftArrow = <FaAngleLeft className="common_arrow" />;

  const [latestProducts, setLatestProducts] = useState();
  const [salesProducts, setSalesProducts] = useState();
  const [mostBoughtProducts, setMostBoughtProducts] = useState();
  const [display,setDisplay] = useState(3);

  useEffect(() => {
    ProductAPI.getLatestProducts()
      .then((rsp) => {
        setLatestProducts(rsp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    ProductAPI.getSalesProducts()
      .then((rsp) => {
        setSalesProducts(rsp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    OrderAPI.frequentlyBought()
      .then((rsp) => {
        setMostBoughtProducts(rsp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    let width = window.innerWidth;
    if(width <= 600)
    {
      setDisplay(1);
    }

  },[]);

  let latestItems = latestProducts?.map((item) => {
    return (
      <Product
        key={item.images[0].front.url}
        image={item.images[0].front.url}
        height={250}
        width={350}
        name={item.productName}
        unitPrice={item.unitPrice}
        salePrice={item.salePrice}
        link={`/product/details/${item._id}`}
      />
    );
  });

  let salesItems = salesProducts?.map((item) => {
    return (
      <Product
        key={item.images[0].front.url}
        image={item.images[0].front.url}
        height={250}
        width={350}
        name={item.productName}
        unitPrice={item.unitPrice}
        salePrice={item.salePrice}
        link={`/product/details/${item._id}`}
      />
    );
  });

  let frequentlyBought = mostBoughtProducts?.map((item) => {
    return (
      <Product
        key={item.images[0].front.url}
        image={item.images[0].front.url}
        height={250}
        width={350}
        name={item.productName}
        unitPrice={item.unitPrice}
        salePrice={item.salePrice}
        link={`/product/details/${item._id}`}
      />
    );
  });

  return (
    <Layout>
      <div className="body">
        <ImageSlider className="d-block w-100 space_around" items={banners} />
        <div
          className="border"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyItems: "center",
            justifyContent: "space-evenly",
            maxHeight: "20%",
          }}
        >
          <p className="centerText common_header">Frequently bought</p>
          <div>
            {frequentlyBought === undefined ? (
              <AppLoader />
            ) : (
              <Carousel
                show={display}
                slide={1}
                transition={0.5}
                swiping={true}
                leftArrow={leftArrow}
                rightArrow={rightArrow}
                useArrowKeys={true}
                responsive={true}
              >
                {frequentlyBought}
              </Carousel>
            )}
          </div>
        </div>
        <div
          className="border"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyItems: "center",
            justifyContent: "space-evenly",
            maxHeight: "20%",
          }}
        >
          <p className="centerText common_header">Latest items</p>
          <div>
            {latestItems === undefined ? (
              <AppLoader />
            ) : (
              <Carousel
                show={display}
                slide={1}
                transition={0.5}
                swiping={true}
                leftArrow={leftArrow}
                rightArrow={rightArrow}
                useArrowKeys={true}
                responsive={true}
              >
                {latestItems}
              </Carousel>
            )}
          </div>
        </div>
        <div
          className="border"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyItems: "center",
            justifyContent: "space-evenly",
            maxHeight: "20%",
          }}
        >
          <p className="centerText common_header">Items on sale</p>
          <div>
            {salesItems === undefined ? (
              <AppLoader />
            ) : (
              <Carousel
                show={display}
                slide={1}
                transition={0.5}
                swiping={true}
                leftArrow={leftArrow}
                rightArrow={rightArrow}
                useArrowKeys={true}
                responsive={true}
              >
                {salesItems}
              </Carousel>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default HomePage;
