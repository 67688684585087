import styles from "./pagination.module.css";

const DataPagination = ({count,active,pageSize, handlePageClick})=>{

    const isActive = (page)=>{
        return page === active? styles.active : null
    };
    
    if (count !== undefined && count !== null) {
        if (count === 0){
            return <p className={styles.empty}>no items available</p>;
        }


        const pages = count / pageSize;
        const remainder = count % pageSize;
        const items = [];
        if (pages <= 1) {
            items.push(<section className={`${styles.linkStyle} ${isActive(1)}`} key={1}  onClick={(evt)=>{handlePageClick(1)}}>
                1
            </section>);
        }
        else if(pages > 1) {
            for(let i=1;i<=pages;i++)
            {
                items.push(<section className={`${styles.linkStyle} ${isActive(i)}`} key={i} onClick={(evt)=>{handlePageClick(i)}}>
                    {i}
                </section>);
            }
        }

        if(remainder !== 0 && (count > pageSize))
        {
            const last = items.length+1;
            items.push(<section className={`${styles.linkStyle} ${isActive(last)}`} key={last}  onClick={(evt)=>{handlePageClick(last)}}>
                {last}
            </section>);
        }
        return <div className={styles.page}>
            {items}
        </div>

    }
    return null;


}

export default DataPagination;