import { Fragment } from "react";

import AdminFooter from "./AdminFooter";
import AdminHeader from "./AdminHeader";

const AdminLayout = (props) => {
  return (
    <Fragment>
      <AdminHeader />
      <main className="admin-main">{props.children}</main>
      <AdminFooter />
    </Fragment>
  );
};

export default AdminLayout;
