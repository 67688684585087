import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

import "../../../css/adminHeader.css";
import { useLoginInfo } from "../../../util/AdminRoute";
import logo from "../../../images/logo.png";

const AdminHeader = (props) => {
  const loginInfo = useLoginInfo();
  const navigate = useNavigate();
  const logoutHandler = (evt) => {
    sessionStorage.removeItem("user");
    navigate("/login");
  };

  return (
    <nav className="admin_header_navbar">
      <div>
        <Link className="admin_header_navbrand" to="/admin">
          <img src={logo} width={80} height={30} alt="logo" /> admin
        </Link>
      </div>
      <div className="admin_header_navigation">
        <div className="admin_header_dropdown">
          User
          <div className="admin_header_dropdown_content">
            <Link className="admin_header_links" to="/admin/create">
              add admin user
            </Link>
            <Link className="admin_header_links" to="/admin/users">
              view users
            </Link>
            <Link className="admin_header_links" to="/admin/changePassword">
              change password
            </Link>
          </div>
        </div>
        <div className="admin_header_dropdown">
          Product
          <div className="admin_header_dropdown_content">
            <Link className="admin_header_links" to="/admin/product/add">
              add new Product
            </Link>
            <Link className="admin_header_links" to="/admin/products">
              view products
            </Link>
            <Link className="admin_header_links" to="/admin/product/images/add">
              add image
            </Link>
            <Link className="admin_header_links" to="/admin/product/images">
              view images
            </Link>
          </div>
        </div>
        <div className="admin_header_dropdown">
          Order
          <div className="admin_header_dropdown_content">
            <Link className="admin_header_links" to="/admin/orders">
              view orders
            </Link>
          </div>
        </div>
        <div className="admin_header_dropdown">
          Color
          <div className="admin_header_dropdown_content">
            <Link className="admin_header_links" to="/admin/colors">
              view colors
            </Link>
            <Link className="admin_header_links" to="/admin/colors/add">
              add color
            </Link>
          </div>
        </div>
        <div className="admin_header_dropdown">
          Style
          <div className="admin_header_dropdown_content">
            <Link className="admin_header_links" to="/admin/styles">
              view styles
            </Link>
            <Link className="admin_header_links" to="/admin/styles/add">
              add style
            </Link>
          </div>
        </div>
        <div className="admin_header_dropdown">
          Material
          <div className="admin_header_dropdown_content">
            <Link className="admin_header_links" to="/admin/materials">
              view materials
            </Link>
            <Link className="admin_header_links" to="/admin/materials/add">
              add material
            </Link>
          </div>
        </div>
        <div className="admin_header_dropdown">
          Size
          <div className="admin_header_dropdown_content">
            <Link className="admin_header_links" to="/admin/sizes">
              view sizes
            </Link>
            <Link className="admin_header_links" to="/admin/sizes/add">
              add size
            </Link>
          </div>
        </div>
        <div className="admin_header_dropdown">
          Category
          <div className="admin_header_dropdown_content">
            <Link className="admin_header_links" to="/admin/category">
              view categories
            </Link>
            <Link className="admin_header_links" to="/admin/category/add">
              add category
            </Link>
          </div>
        </div>
      </div>
      <div className="admin_header_auth_area">
        <span>Welcome,{loginInfo?.user.sub}</span>
        <span> | </span>
        <span className="admin_header_logout" onClick={logoutHandler}>
          logout
        </span>
      </div>
    </nav>
  );
};

export default AdminHeader;
