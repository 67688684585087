import { useState, useEffect } from "react";
import { Pagination } from "react-bootstrap";
import { useParams } from "react-router";

import Accordion from "../common/Accordion";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Layout from "../Layout";
import "../../css/productItems.css";
import * as ProductAPI from "../../service/ProductAPI";
import Product from "../common/Product";
import AppLoader from "../common/AppLoader";

const ProductPage = (props) => {
  const max = 500000;
  const [filterPrice, setFilterPrice] = useState([1,max]);
  const [latestProducts, setLatestProducts] = useState();
  const { gender, productType, style } = useParams();

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(1);
  const [count, setCount] = useState(0);
  const [active, setActive] = useState(0);


  const [colors, setColors] = useState();
  const [sizes, setSizes] = useState();
  const [brands, setBrands] = useState();

  const [fColors, setFColors] = useState([]);
  const [fSizes, setFSizes] = useState([]);
  const [fBrands, setFBrands] = useState([]);
  const [isLoading, setIsLoading] = useState(false);



  useEffect(() => {
    let searchTerms = {};
    if(filterPrice.length === 2)
    {
      searchTerms = {...searchTerms, "filterPrices": filterPrice};
    }
    if(fColors.length >= 1)
    {
      searchTerms = {...searchTerms, "filterColors": fColors};
    }
    if(fSizes.length >= 1)
    {
      searchTerms = {...searchTerms, "filterSizes": fSizes};
    }
    if(fBrands.length >= 1)
    {
      searchTerms = {...searchTerms, "filterBrands": fBrands};
    }
    setIsLoading(true);
    ProductAPI.getProductsCatalogWithSearch(
      gender,
      productType,
      style,
      pageNumber,
      pageSize,
      searchTerms
    )
      .then((rsp) => {
        setLatestProducts(rsp.data.products);
        setCount(rsp.data.count);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [gender, productType, style, pageNumber, pageSize,filterPrice,fColors,fBrands,fSizes]);

  useEffect(() => {
    ProductAPI.getBrands(gender, productType, style)
      .then((rsp) => {
        const data = rsp.data.map((d) => d.brand);
        setBrands(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [gender, productType, style]);

  useEffect(() => {
    ProductAPI.getFilterColors(gender, productType, style)
      .then((rsp) => {
        const data = new Set(rsp.data.map(o => o.color));
        setColors(Array.from(data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [gender, productType, style]);

  useEffect(() => {
    ProductAPI.getFilterSizes(gender, productType, style)
      .then((rsp) => {
        const data = new Set(rsp.data.map(o => o.size));
        setSizes(Array.from(data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [gender, productType, style]);

  
  const colorFilterHandler = (evt) => {
    if (fColors.includes(evt.target.value)) {
      setFColors(prev => prev.filter((v) => v !== evt.target.value));
    }
    else {
      setFColors(prev => prev.concat(evt.target.value));
    }
  }

  const sizeFilterHandler = (evt) => {
    if (fSizes.includes(evt.target.value)) {
      setFSizes(prev => prev.filter((v) => v !== evt.target.value));
    }
    else {
      setFSizes(prev => prev.concat(evt.target.value));
    }
  }

  const brandFilterHandler = (evt) => {
    if (fBrands.includes(evt.target.value)) {
      setFBrands(prev => prev.filter((v) => v !== evt.target.value));
    }
    else {
      setFBrands(prev => prev.concat(evt.target.value));
    }
  }

  let fetchedColors = colors?.map((item) => {
    return (
      <li key={item} className="product_items_list_items">
        <input type="checkbox" name="color" value={item} onChange={colorFilterHandler} />
        <label>{item}</label>
      </li>
    );
  });

  let fetchedSizes = sizes?.map((item) => {
    return (
      <li key={item} className="product_items_list_items">
        <input type="checkbox" name="size" value={item} onChange={sizeFilterHandler} />
        <label>{item}</label>
      </li>
    );
  });

  let fetchedBrands = brands?.map((item) => {
    return (
      <li key={item} className="product_items_list_items">
        <input type="checkbox" name="brand" value={item} onChange={brandFilterHandler} />
        <label>{item}</label>
      </li>
    );
  });

  let latestItems = latestProducts?.map((item) => {
    return (
      <Product
        key={item.images[0]}
        image={item.images[0]}
        height={250}
        width={350}
        name={item.productName}
        unitPrice={item.unitPrice}
        salePrice={item.salePrice}
        link={`/product/details/${item.id}`}
      />
    );
  });

  const pageLinks = [];

  if (count > 0) {
    const linkNumbers = count / pageSize;
    if (linkNumbers === 0) {
      pageLinks.push(<Pagination.Item key={0} active={1 === active}>
        {1}
      </Pagination.Item>)
    }
    for (let i = 0; i < linkNumbers; i++) {
      pageLinks.push(<Pagination.Item key={i} onClick={(evt) => { setPageNumber(i); setActive(i) }} active={i === active}>
        {i + 1}
      </Pagination.Item>)
    }
  }

  return (
    <Layout>
      <form onSubmit={(evt) => { evt.preventDefault(); }}>
        <div className="product_items">
          <div className="product_items_filter">
            <span className="product_items_header">Filter</span>
            <hr />
            <main>
              <Accordion header="Brand">
                <ul className="product_items_list">
                  {fetchedBrands === undefined ? (
                    <AppLoader/>
                  ) : (
                    fetchedBrands
                  )}
                </ul>
              </Accordion>
              <Accordion header="Color">
                <ul className="product_items_list">
                  {fetchedColors === undefined ? (
                    <AppLoader/>
                  ) : (
                    fetchedColors
                  )}
                </ul>
              </Accordion>
              <Accordion header="Size">
                <ul className="product_items_list">
                  {fetchedSizes === undefined ? (
                    <AppLoader/>
                  ) : (
                    fetchedSizes
                  )}
                </ul>
              </Accordion>
              <Accordion header="Price">
                
                <span style={{margin: "0px 4px"}}>₦{filterPrice[0]} - ₦{filterPrice[1]}</span>
                <Slider allowCross={false} defaultValue={[1, max]} value={filterPrice} 
                onChange={(value)=>setFilterPrice(value)}
                 range={true} min={1} max={max} ariaLabelForHandle={["min","max"]} style={{color: "red"}} />
              
              </Accordion>
            </main>
          </div>
          <div className="product_items_catalog">
            <section>
              <span className="product_display_label">display size:
                <select className="product_select_label" value={pageSize} onChange={(evt) => { setPageSize(evt.target.value); setPageNumber(0); setActive(0) }}>
                  <option value={1}>1</option>
                  <option value={5} >5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                </select></span>
            </section>
            <hr />
            <section className="product_items_catalog_block">
              {!isLoading ? latestItems : <AppLoader/>}
            </section>
            <hr />
            {count <= 0 && !isLoading ? <p className="no_item">No items available</p> :
              <Pagination >
                <Pagination.First onClick={(evt) => { setPageNumber(0); setActive(0) }} />
                <Pagination.Prev onClick={(evt) => {
                  if (pageNumber > 0) {
                    setPageNumber(prev => prev - 1); setActive(prev => prev - 1);
                  }
                }} />
                {pageLinks}
                <Pagination.Next onClick={(evt) => {
                  if (pageNumber < ((count / pageSize) - 1)) {
                    setPageNumber(prev => prev + 1); setActive(prev => prev + 1);
                  }
                }}
                />
                <Pagination.Last onClick={(evt) => {
                  if (count <= pageSize) {
                    setPageNumber(0); setActive(0);
                  }
                  else {
                    setPageNumber((count / pageSize) - 1); setActive((count / pageSize) - 1);
                  }

                }} />
              </Pagination >
            }
          </div>

        </div>
      </form>
    </Layout>
  );
};

export default ProductPage;
