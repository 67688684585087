import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  FaCcVisa,
  FaCcMastercard,
  FaFacebook,
  FaInstagramSquare,
  FaSnapchatSquare,
  FaPinterestP,
} from "react-icons/fa";

import logo from "../images/logo.png";
import "../css/footer.css";
import * as ProductAPI from "../service/ProductAPI";
import AppLoader from "./common/AppLoader";

const Footer = (props) => {
  const [link, setLink] = useState();

  useEffect(() => {
    if (sessionStorage.getItem("footerLinks")) {
      setLink(JSON.parse(sessionStorage.getItem("footerLinks")));
    } else {
      ProductAPI.getFooterLinks()
        .then((data) => {
          setLink(data.data);
          sessionStorage.setItem("footerLinks", JSON.stringify(data.data));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const footerLink = link?.map((li) => {
    return li.data.length > 0 ? (
      <div key={li.header} className="footer_links">
        <ul>
          <li className="footer_item_header">{li.header}</li>
          {li.data.map((l) => {
            return (
              <li key={l.concat(li.header)}>
                <Link className="footer_link"
                  key={l.concat(li.header)}
                  to={`/product/${li.gender}/${li.type}/${l}`}
                >
                  {l}
                </Link></li>
            );
          })}
        </ul>
      </div>
    ) : null;
  });

  return (
    <div className="footer_container">
      <div className="footer_row">
        <div className="footer_address">
          Shop 2,
          <br />
          92, Fred macween street,
          <br />
          Balogun,
          <br />
          Lagos,Nigeria.
        </div>

        {link !== undefined ? footerLink : <AppLoader />}
      </div>

      <div className="footer_row">

        <div className="footer_row_card">
          <FaCcVisa className="footer_card" />

          <FaCcMastercard className="footer_card" />

          <span className="footer_card verve">verve</span>

        </div>
        <div className="footer_row_card">
          <p className="footer_copy">
            <span style={{color:"#054e4e"}}>Copyright &copy; {new Date().getFullYear()}</span>
            </p>
            <img src={logo} width={80} height={50} alt="logo" />
         
        </div>

        <div className="footer_row_card">
          <a className="footer_social" href="https://www.facebook.com/profile.php?id=100054294799583&mibextid=LQQJ4d" target="_blank" rel="noreferrer">
            <FaFacebook />
          </a>

          <a className="footer_social" href="https://instagram.com/razzmatazzme?igshid=MmIzYWVlNDQ5Yg==" target="_blank" rel="noreferrer">
            <FaInstagramSquare />
          </a>

          <a className="footer_social" href="https://www.snapchat.com" target="_blank" rel="noreferrer">
            <FaSnapchatSquare />
          </a>

          <a className="footer_social" href="https://www.pinterest.com" target="_blank" rel="noreferrer">
            <FaPinterestP />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
